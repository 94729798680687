import React, { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  HandleCleanProjects,
  LoadProjectById,
  LoadProjects,
  LoadResponsibles,
  LoadRoles,
  MainContext,
  LoadDicTypes,
  LoadDicTypesTermin,
  FindDynamicSVGReactElement,
  ImportSVGIconStringForImg,
} from './MainContext';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import SockJsClient from 'react-stomp';
import { fetchMeUser, fetchMeUserFull, getResponsibles } from '../../store/slices/personsSlice/personsSlice';
import useSearchApplications from './useSearchApplications';
import { ApplicationsMapSearchType, ApplicationsSearchType } from '../../lib/interfaces/SearchApplicationsBody';
import { Navbar } from '../../components/Navbar/Navbar';
import LocalStorageService from '../../services/LocalStorageService';
import AppRoutes from '../../router/AppRoutes';
import usePermissions from '../../lib/utils/usePermissions';
import AuthService from '../../services/AuthService';
import { cleanProjects, getProjectById, getProjects } from '../../store/slices/projectsSlice/projectsSlice';
import { Spin } from 'antd';
import { checkIsLoaded, checkIsLoading } from '../../lib/utils/checkLoadingStatus';
import {
  getUnreadNotificationsCount,
  pushNewNotification,
} from '../../store/slices/notificationsSlice/notificationsSlice';
import localStorageService from '../../services/LocalStorageService';
import { getPersonTypesDictionary } from '../../store/slices/dictionariesSlice/dictionariesSlice';
import { getProperties } from '../../store/slices/rootSlice/rootSlice';
import useWindowDimensions from '../../lib/utils/useWindowDimensions';
import { fetchApplicationIcons } from '../../store/slices/applicationsSlice/applicationsSlice';
import { DynamicSVGReactElement } from '../../components/common/DynamicSVGReactElement';
import { collectionSvg } from '../../lib/utils/collectionSvg';
import { getRoles } from '../../store/slices/rolesSlice/rolesSlice';
import {
  getDicTypes,
  getDicTypesTermin,
} from '../../store/slices/administrationDictionariesSlice/administrationDictionariesSlice';

interface IProps {}

export const MainContainer: FC<IProps> = () => {
  const dispatch = useAppDispatch();
  const isAuthenticated = !!LocalStorageService.getAccessToken();
  const [hasAccess] = usePermissions();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchType, setSearchType] = useState<ApplicationsSearchType>('ALL');
  const [mapSearchType, setMapSearchType] = useState<ApplicationsMapSearchType>('MAP_ALL');
  const { meUserFull, meUserFullLoading } = useAppSelector((state) => state.persons);
  const { personTypesLoading } = useAppSelector((state) => state.dictionaries);
  const { propertiesLoading } = useAppSelector((state) => state.root);
  const { applicationIcons } = useAppSelector((state) => state.applications);

  const searchApplicationsStateMy = useSearchApplications({
    searchType: 'MY',
    hasAccess,
  });
  const searchApplicationsStateAll = useSearchApplications({
    searchType: 'ALL',
    hasAccess,
  });
  const searchApplicationsMapStateMy = useSearchApplications({
    searchType: 'MAP_MY',
    hasAccess,
  });
  const searchApplicationsMapStateAll = useSearchApplications({
    searchType: 'MAP_ALL',
    hasAccess,
  });

  const windowDimensions = useWindowDimensions();

  const getSearchApplicationsState = () => {
    const currentTypes: {
      '/applications/list': ApplicationsSearchType;
      '/applications/list/': ApplicationsSearchType;
      '/applications/map': ApplicationsMapSearchType;
      '/applications/map/': ApplicationsMapSearchType;
    } = {
      '/applications/list': searchType,
      '/applications/list/': searchType,
      '/applications/map': mapSearchType,
      '/applications/map/': mapSearchType,
    };
    switch (currentTypes[pathname]) {
      default:
      case 'MY':
        return searchApplicationsStateMy;
      case 'ALL':
        return searchApplicationsStateAll;
      case 'MAP_MY':
        return searchApplicationsMapStateMy;
      case 'MAP_ALL':
        return searchApplicationsMapStateAll;
    }
  };

  const loadResponsibles: LoadResponsibles = ({ projectIds, personStatusIds }) => {
    dispatch(getResponsibles({ projectIds, personStatusIds }));
  };

  const loadProjects: LoadProjects = (searchParams) => {
    return dispatch(getProjects(searchParams || {}));
  };

  const loadProjectById: LoadProjectById = (projectId) => {
    dispatch(getProjectById(projectId));
  };

  const loadDicTypes: LoadDicTypes = () => {
    return dispatch(getDicTypes());
  };

  const loadDicTypesTermin: LoadDicTypesTermin = (dicTypeId) => {
    return dispatch(getDicTypesTermin(dicTypeId));
  };

  const handleCleanProjects: HandleCleanProjects = () => {
    dispatch(cleanProjects());
  };

  const findDynamicSVGReactElement: FindDynamicSVGReactElement = (dicId, dopIconName, typeIco, subjectId) => {
    const findNameIcon = applicationIcons.find((icon) => icon.dicId === subjectId)?.[typeIco];

    return <DynamicSVGReactElement name={findNameIcon || dopIconName} dopIcon={dopIconName} dicId={dicId} />;
  };

  const importSVGIconStringForImg: ImportSVGIconStringForImg = (dicId, dopIconName, typeIco, subjectId) => {
    const findIcon = applicationIcons.find((icon) => icon.dicId === subjectId);
    if (findIcon) {
      return collectionSvg[dicId][findIcon[typeIco]];
    } else {
      return collectionSvg[dicId][dopIconName] || '';
    }
  };

  const loadRoles: LoadRoles = (searchParams) => {
    return dispatch(getRoles(searchParams));
  };

  // Загрузка текущего пользователя
  useEffect(() => {
    const currentUser = AuthService.getCurrentUser();
    if (currentUser) {
      /* Странно конечно, получать id пользователя, а после запрашивать по нему данные. Но, информации в me не хватает
        также currentUser содержит только personId, а нужен userId */
      dispatch(fetchMeUser()).then(({ payload }) => {
        if (payload?.id) {
          dispatch(fetchMeUserFull({ personId: payload.id }));
        } else {
          LocalStorageService.clearToken();
          navigate('/login');
        }
      });
    }
  }, [dispatch]);

  useEffect(() => {
    !checkIsLoaded(personTypesLoading) && dispatch(getPersonTypesDictionary());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getProperties({ tenantId: 1 }));
    isAuthenticated && dispatch(fetchApplicationIcons({ dicType: 'app_subject' }));
  }, [dispatch]);

  useEffect(() => {
    if (meUserFull?.userId) {
      // dispatch(getNotifications({page: 0, size: 20, userId: meUserFull.userId, searchUnread: true}))
      dispatch(getUnreadNotificationsCount());
    }
  }, [meUserFull, dispatch]);

  if (isAuthenticated) {
    return (
      <Spin spinning={checkIsLoading(meUserFullLoading, personTypesLoading)}>
        {checkIsLoaded(meUserFullLoading, personTypesLoading, propertiesLoading) && (
          <MainContext.Provider
            value={{
              hasAccess,
              searchType,
              mapSearchType,
              setSearchType,
              setMapSearchType,
              searchApplicationsState: getSearchApplicationsState(),
              loadResponsibles,
              loadProjects,
              loadDicTypes,
              loadDicTypesTermin,
              handleCleanProjects,
              loadRoles,
              windowDimensions,
              loadProjectById,
              findDynamicSVGReactElement,
              importSVGIconStringForImg,
              searchApplicationsMapStateAll,
            }}
          >
            <SockJsClient
              url={`https://api.sdn104.iflex.ru/notifications/notifications/?access_token=${localStorageService.getAccessToken()}`}
              onMessage={(msg) => {
                dispatch(pushNewNotification(msg));
              }}
              topics={[`/user/${meUserFull?.userId}/push`]}
            />
            <Navbar />
            <AppRoutes isAuth={true} />
          </MainContext.Provider>
        )}
      </Spin>
    );
  }

  return <AppRoutes isAuth={false} />;
};
