import React, { FC, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Checkbox, Col, Divider, Modal, Radio, Row, Table, Tooltip, Form, message } from 'antd';
import type { ColumnsType } from 'antd/lib/table';
import { isEmpty, uniqBy } from 'lodash';
import moment from 'moment';
import { ReactComponent as Papers } from '../../lib/img/papers.svg';
import { ReactComponent as MapPoint } from '../../lib/img/map-point.svg';
import { ReactComponent as UrgentIcon } from '../../lib/img/urgent-icon.svg';
import { ReactComponent as SortIcon } from '../../lib/img/sort-icon.svg';
import { ReactComponent as SortMark } from '../../lib/img/sort-mark.svg';
import { ReactComponent as AlarmClock } from '../../lib/img/alarm-clock.svg';
import usePhoneMask from '../../lib/utils/usePhoneMask';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { Application } from '../../lib/interfaces/Application';
import { toDisplayFormat } from '../../lib/utils/formatDate';
import { checkIsLoaded, checkIsLoading } from '../../lib/utils/checkLoadingStatus';
import { useMainContext } from '../../containers/MainContainer/MainContext';
import { ApplicationsSearchType, ApplicationsSortField } from '../../lib/interfaces/SearchApplicationsBody';
import { SearchApplications } from './SearchApplications';
import { useDictionaries } from '../../lib/utils/useDictionaries';
import { getFio } from '../../lib/utils/applications';
import './ApplicationsList.less';
import { ApplicationsReportModal } from './ApplicationsReportModal';
import CheckFavoriteApplication from '../common/CheckApplication';
import { applicationsFavoritesChangeRoute } from '../../lib/routes/applications';
import axios from 'axios';
import { FindAndFormatLinkForText } from '../common/FindAndFormatLinkForText';
import { ReactComponent as HandIcon } from '../../lib/img/hand-icon.svg';
import { ReactComponent as CirculRedIcon } from '../../lib/img/circul-red-icon.svg';
import FireBaseService from '../../services/FireBaseService/FireBaseService';
import { ReactComponent as DonateIco } from '../../lib/img/buy_donate.svg';
import { ReactComponent as NoDonateIco } from '../../lib/img/no_donate.svg';
import { AppTypes } from '../../lib/const/appTypes';
import ApplicationTypeIdButtons from '../ApplicationTypeIdButtons/ApplicationTypeIdButtons';

export const ApplicationsList: FC = () => {
  const navigate = useNavigate();
  const { getMaskedPhoneNumber } = usePhoneMask();
  const { searchApplicationsState, setSearchType, hasAccess, searchType, loadProjects, importSVGIconStringForImg } =
    useMainContext();
  const { loadDictionaries } = useDictionaries();
  const { projectsLoading } = useAppSelector((state) => state.projects);
  const { meUserFull } = useAppSelector((state) => state.persons);

  // Получение списка просьб по изменению все/мои
  useEffect(() => {
    searchApplicationsState.getApplicationsList();
  }, [searchType]);

  // Загрузка словарей
  useEffect(() => {
    loadDictionaries([
      'app_status',
      'app_subject',
      'person_category',
      'region',
      'regularity_of_assistance',
      'subway_station',
    ]);
    if (!checkIsLoaded(projectsLoading)) loadProjects();
  }, []);

  const { applicationsList, applicationsListLoading, applicationsMyCheck } = useAppSelector(
    (state) => state.applications
  );
  const { appSubject, appStatus } = useAppSelector((state) => state.dictionaries);

  const [sortModalVisible, setSortModalVisible] = useState<boolean>(false);
  const [reportModalVisible, setReportModalVisible] = useState<boolean>(false);
  const sortedFieldValue = searchApplicationsState.getSearchFormFieldValue('sortField');

  const [tableData, setTableData] = useState<Application[]>([]);

  // Данные таблицы
  useEffect(() => {
    const data = applicationsList.content.map((application) => {
      const executionDateText = application?.executionDate
        ? moment(application?.executionDate, 'YYYY-MM-DD').format('DD MMMM')
        : '';
      const executionTimeText = application?.executionTime?.toLocaleLowerCase() || '';
      return {
        ...application,
        key: application.id,
        subjectId: application.subjectId,
        statusName: appStatus.find(({ id }) => id === application.statusId)?.name,
        executionDateValue: [executionDateText, executionTimeText].filter(Boolean).join(', '),
        liked: application.liked,
      };
    });
    setTableData(data);
  }, [applicationsList, appSubject, appStatus]);

  const openSortModal = () => {
    setSortModalVisible(true);
  };

  const sortFields: { value: ApplicationsSortField; title: string }[] = [
    { value: 'CREATE_DATE', title: 'Дата получения' },
    { value: 'WARD', title: 'Подопечный' },
    { value: 'PROJECT', title: 'Проект' },
    { value: 'SUBJECT', title: 'Вид помощи' },
  ];

  const renderSortModal = () => {
    const closeSortModal = () => {
      setSortModalVisible(false);
    };
    const handleSort = ({ sortField }: { sortField: ApplicationsSortField }) => {
      searchApplicationsState.handleSetSearchFormFields({
        sortField,
      });
      closeSortModal();
    };

    return (
      <Modal
        visible={sortModalVisible}
        onCancel={closeSortModal}
        footer={null}
        centered
        bodyStyle={{ backgroundColor: '#F4F4F4' }}
      >
        <Form onFinish={handleSort}>
          <h2>Выберите поле сортировки</h2>
          <Divider className="thin-divider mt10" />
          <Form.Item name="sortField" initialValue={sortedFieldValue}>
            <Radio.Group className="mb20">
              {sortFields.map(({ value, title }) => (
                <div key={value}>
                  <Radio value={value}>{title}</Radio>
                </div>
              ))}
            </Radio.Group>
          </Form.Item>
          <Row justify="center" gutter={20}>
            <Col>
              <Button onClick={closeSortModal}>Отмена</Button>
            </Col>
            <Col>
              <Button type="primary" htmlType="submit">
                Отсортировать
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  };

  const getMenuItemStyle = (key: ApplicationsSearchType) => {
    if (key === searchType) {
      return {
        backgroundColor: 'white',
        fontWeight: 'bold',
      };
    }
    return { boxShadow: '0 0 2px 0 rgba(0, 0, 0, 0.15) inset' };
  };

  const changeSearchType = (key: ApplicationsSearchType) => {
    switch (key) {
      case 'MY':
        applicationsMyCheck
          ? setSearchType(key)
          : Modal.error({
              content: 'Нет просьб для просмотра в этом разделе',
              width: '534px',
              icon: null,
            });
        break;

      case 'ALL':
        setSearchType(key);
        break;

      default:
        break;
    }
  };

  const renderMenu = () => {
    return (
      <div className="applications-list-header ml20 mr20 pt20 pb20">
        <div className="display-flex">
          <div className="applications-list-menu">
            <div
              className="applications-list-menu-item applications-list-menu-item-left"
              style={getMenuItemStyle('ALL')}
              onClick={() => {
                changeSearchType('ALL');
              }}
            >
              Все просьбы
            </div>

            <div
              className="applications-list-menu-item applications-list-menu-item-right"
              style={getMenuItemStyle('MY')}
              onClick={() => {
                changeSearchType('MY');
              }}
            >
              Мои просьбы
            </div>
          </div>

          <Checkbox
            className="ml24 mt5"
            onChange={(e) => {
              searchApplicationsState.handleSetSearchFormFields({
                urgent: e.target.checked,
              });
            }}
            checked={searchApplicationsState.getSearchFormFieldValue('urgent')}
          >
            Срочная просьба
          </Checkbox>

          <Checkbox
            className="ml24 mt5"
            onChange={(e) => {
              searchApplicationsState.handleSetSearchFormFields({
                liked: e.target.checked ? [meUserFull?.id] : undefined,
              });
            }}
            checked={searchApplicationsState.getSearchFormFieldValue('liked')}
          >
            Избранное
          </Checkbox>

          <Checkbox
            className="ml24 mt5"
            onChange={(e) => {
              searchApplicationsState.handleSetSearchFormFields({
                appStatusIdExclude: e.target.checked ? [6] : [],
              });
            }}
            checked={searchApplicationsState.getSearchFormFieldValue('appStatusIdExclude').length}
          >
            Исключить закрытые
          </Checkbox>

          <Tooltip title="Сортировка">
            <SortIcon className="ml14 mt5 cursor-pointer" onClick={openSortModal} />
          </Tooltip>
        </div>

        <ApplicationTypeIdButtons
          searchApplicationsState={searchApplicationsState}
          style={{ position: 'relative', marginLeft: 'auto', marginRight: 'auto' }}
        />

        <Button className="mr20 display-flex">
          <Link className="ml-auto mr-auto display-flex" to="/applications/map">
            <MapPoint className="mr7" />
            Поиск на карте
          </Link>
        </Button>

        {hasAccess(['APPLICATIONS.REPORT', 'APPLICATIONS.REPORT.ALLREGIONS']) && (
          <Button
            className="display-flex"
            onClick={() => {
              setReportModalVisible(true);
            }}
          >
            <div className="ml-auto mr-auto display-flex">
              <Papers className="mr7 mt1" />
              Сформировать отчет
            </div>
          </Button>
        )}
      </div>
    );
  };

  const getSortedTitle = (title: string): React.ReactNode => {
    const fieldValue = sortFields.find((item) => item.title === title)?.value;
    if (fieldValue === sortedFieldValue) {
      return (
        <>
          {title} <SortMark />
        </>
      );
    }
    return <>{title}</>;
  };

  const onChangeFavoritesChecked = async (idApp: string, checked: boolean) => {
    FireBaseService.createLogEventFireBase('Событие. Добавить просьбу в избранное');

    if (checked) {
      await axios
        .delete(applicationsFavoritesChangeRoute({ applicationId: idApp }))
        .then((response) => {
          searchApplicationsState.getApplicationsList();
        })
        .catch(() => {
          message.destroy();
          message.error(
            <>
              <div>При удалении просьбы из избранного возникла ошибка.</div>
              <div>Пожалуйста, повторите попытку позднее</div>
            </>,
            3
          );
        });
    } else {
      await axios
        .post(applicationsFavoritesChangeRoute({ applicationId: idApp }))
        .then((response) => {
          searchApplicationsState.getApplicationsList();
        })
        .catch(() => {
          message.destroy();
          message.error(
            <>
              <div>При добавлении просьбы в избранного возникла ошибка.</div>
              <div>Пожалуйста, повторите попытку позднее</div>
            </>,
            3
          );
        });
    }
  };

  const columns: ColumnsType<any> = [
    {
      key: 'urgent',
      dataIndex: 'urgent',
      width: '40px',
      render: (urgent) => (urgent ? <UrgentIcon /> : <></>),
    },
    {
      key: 'potentialExecutors',
      dataIndex: 'potentialExecutors',
      width: '40px',
      render: (potentialExecutors) => {
        return hasAccess(['APPLICATIONS.INDICATOR']) && potentialExecutors?.length > 0 ? (
          <Row style={{ width: 20, height: 24, position: 'relative' }}>
            <HandIcon style={{ position: 'absolute', zIndex: 1 }} />
            <CirculRedIcon style={{ position: 'absolute', zIndex: 2, left: 12, top: '-1px' }} />
            <div
              style={{
                position: 'absolute',
                zIndex: 3,
                color: '#fff',
                left: `${potentialExecutors?.length < 10 ? '15.8' : '13.3'}px`,
                top: '-2px',
                fontSize: 9,
              }}
            >
              <Row justify={'center'} wrap={false}>
                <div>{potentialExecutors?.length > 9 ? '9' : potentialExecutors?.length}</div>

                {potentialExecutors?.length > 9 && (
                  <div style={{ height: 12, display: 'flex', alignItems: 'center' }}>+</div>
                )}
              </Row>
            </div>
          </Row>
        ) : (
          <></>
        );
      },
    },
    {
      key: 'createDate',
      dataIndex: 'createDate',
      title: getSortedTitle('Дата получения'),
      render: (createDate) => toDisplayFormat(moment(createDate, 'YYYY-MM-DD')),
      width: '9%',
    },
    {
      key: 'executionDateValue',
      dataIndex: 'executionDateValue',
      title: getSortedTitle('Дата исполнения'),
      render: (executionDateValue) =>
        executionDateValue ? (
          <>
            <AlarmClock style={{ height: '20px' }} /> {executionDateValue}
          </>
        ) : (
          ''
        ),
      width: '9%',
    },
    {
      key: 'ward',
      dataIndex: 'ward',
      title: getSortedTitle('Подопечный'),
      render: (ward) =>
        hasAccess(['APPLICATION.READ.TECHINFO']) ? (
          <Link to={`/files/${ward?.id}/general`} onClick={(e) => e.stopPropagation()} target="_blank">
            {ward && getFio(ward)}
          </Link>
        ) : (
          <>{ward && getFio(ward)}</>
        ),
      width: '10%',
    },
    {
      key: 'phones',
      dataIndex: 'phones',
      title: 'Телефон',
      render: (phones) => {
        if (isEmpty(phones)) return '';
        const mainPhone = phones.find(({ phoneTypeId }) => phoneTypeId === 1) || phones[0];
        const phoneTitle = phones.map((phone) => getMaskedPhoneNumber(phone.phone)).join('\n');
        return <span title={phoneTitle}>{getMaskedPhoneNumber(mainPhone.phone)}</span>;
      },
      width: '9%',
    },
    {
      key: 'address',
      dataIndex: 'address',
      title: 'Адрес',
      render: (address) => (address ? address.unstructuredAddress : ''),
      width: '16%',
    },
    {
      key: 'project',
      dataIndex: 'project',
      title: getSortedTitle('Проект'),
      render: (project) => (project ? project.title : ''),
      width: '7%',
    },
    {
      key: 'subjectId',
      dataIndex: 'subjectId',
      title: getSortedTitle('Вид помощи'),
      width: '8%',
      render: (subjectId) => {
        const subjectFind = appSubject.find(({ id }) => id === subjectId);

        const svgFindImport = importSVGIconStringForImg('app_subject', 'help', 'iconName', subjectFind?.id);

        return subjectFind ? (
          <div className="display-flex" style={{ justifyContent: 'center', alignItems: 'center' }}>
            <div
              className="display-flex icon-application-select"
              style={{ alignItems: 'center', minWidth: 24, maxWidth: 24, minHeight: 24, maxHeight: 24 }}
            >
              <img style={{ minWidth: 24, maxWidth: 24, minHeight: 24, maxHeight: 24 }} src={svgFindImport} alt="" />
            </div>
            <span className="ml5 ant-select-item-option-content">{subjectFind?.name || ''}</span>
          </div>
        ) : null;
      },
    },
    {
      key: 'text',
      dataIndex: 'text',
      title: 'Текст просьбы',
      render: (text) => <FindAndFormatLinkForText text={text} />,
    },
    {
      key: 'statusName',
      dataIndex: 'statusName',
      title: 'Статус',
      width: '10%',
    },
    {
      key: 'liked',
      dataIndex: 'liked',
      title: '',
      width: '',
      className: 'width-24',
      render: (liked, record) => {
        const findId = liked?.some((id) => id === meUserFull?.id);
        return (
          <CheckFavoriteApplication
            checked={findId}
            onCheck={() => onChangeFavoritesChecked(record.id, findId)}
            style={{ position: 'absolute', top: 0, left: 0 }}
          />
        );
      },
    },
  ];

  // if (!hasAccess(['APPLICATIONS.READ', 'APPLICATIONS.READ.ALLREGIONS'])) return <HasNotPermissions />;

  return (
    <div className="applications-list">
      {renderSortModal()}
      {renderMenu()}
      <Divider className="thin-divider mb0 mt0" />
      <SearchApplications />
      <Table
        className="ml10 mr10"
        columns={columns}
        loading={checkIsLoading(applicationsListLoading)}
        // TODO Eugene. Исправить, когда бэк поправят - заплатка от просьб с одинаковым id
        dataSource={uniqBy(tableData, 'id')}
        size="small"
        onRow={({ id }) => ({
          onClick: () => navigate(`/applications/list/${id}/main`),
        })}
        pagination={{
          position: ['bottomCenter'],
          current: Number(searchApplicationsState.getSearchFormFieldValue('page')) + 1,
          total: applicationsList.totalElements,
          onChange: (page) => {
            searchApplicationsState.handleSetSearchFormFields({
              page: page - 1,
            });
          },
          showSizeChanger: false,
        }}
        // scroll={{ y: 450 }}
      />
      <ApplicationsReportModal
        closeModal={() => {
          setReportModalVisible(false);
        }}
        visible={reportModalVisible}
      />
    </div>
  );
};
