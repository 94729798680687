import React, { FC, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Form, Input, Button, Divider, Row, Col, DatePicker, Select, Checkbox, Radio } from 'antd';
import moment from 'moment-timezone';
import type { RadioChangeEvent } from 'antd';
import MaskedInput from 'antd-mask-input';
import { useAppSelector } from '../../store/hooks';
import User from '../../lib/interfaces/User';
import usePhoneMask from '../../lib/utils/usePhoneMask';
import { displayFormat } from '../../lib/utils/formatDate';
import './CreateUser.less';
import SuggestInput from '../SuggestInput/SuggestInput';
import { fioValidator } from '../../lib/utils/fioValidator';
import { fioLengthValidator } from '../../lib/utils/fioLengthValidator';
import { emailPatternMain } from '../../lib/const/patterns';
import InputMask from 'react-input-mask';
import { datePikerMaskDefault } from '../../lib/const/masks';

interface ICreateUser {
  createUser: (userData: User) => void;
}

export const CreateUser: FC<ICreateUser> = (props) => {
  const { createUser } = props;
  const [hasChronicDiseases, setHasChronicDiseases] = useState(false);
  const { maritalStatus, region, subwayStation } = useAppSelector((state) => state.dictionaries);
  const { phoneMask, getUnmaskedPhoneNumber, phoneInputPlaceholder, phoneValidator } = usePhoneMask();
  const { properties } = useAppSelector((state) => state.root);

  const [form] = Form.useForm();

  const handleSetHasChronicDiseases = (e: RadioChangeEvent) => {
    setHasChronicDiseases(e.target.value);
    if (e.target.value === false) {
      form.resetFields(['chronicDiseases']);
    }
  };

  const clearFieldErrors = () => {
    form.validateFields(['middleName']);
  };

  const refValueUnstructuredAddress = useRef<string>('');

  const onFinish = (values) => {
    const unstructuredAddressValue = form.getFieldValue('unstructuredAddress');

    if (unstructuredAddressValue) {
      if (
        refValueUnstructuredAddress &&
        refValueUnstructuredAddress.current.trim() === unstructuredAddressValue.trim()
      ) {
        form.validateFields(['unstructuredAddress']);
        createUser(values);
      }
    } else {
      createUser(values);
    }
  };

  const DATE_FORMAT = 'MM/DD/YYYY';

  return (
    <div className="create-user-container">
      <div className="create-user-innerContainer">
        <div className="logo mt60 mb15" />
        <h1>Анкета добровольца</h1>
        <Divider />
        <Form
          form={form}
          name="basic"
          initialValues={{
            noMiddleName: false,
            awarenessSource: [],
          }}
          layout="vertical"
          onFinish={onFinish}
        >
          <Row gutter={20}>
            <Col span={12}>
              <Form.Item
                label="Фамилия"
                name="lastName"
                rules={[
                  { required: true, message: '' },
                  {
                    validator: fioValidator,
                    message: 'Недопустимые символы',
                  },
                  {
                    validator: fioLengthValidator,
                    message: 'Максимум 64 символа',
                  },
                ]}
              >
                <Input placeholder="Введите фамилию" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Дата рождения"
                name="birthDate"
                rules={[{ required: true, message: '' }]}
                style={{ width: '150px' }}
              >
                <DatePicker
                  placeholder="ДД.ММ.ГГГГ"
                  format={displayFormat}
                  disabledDate={(current) => {
                    return moment(current).isAfter(moment());
                  }}
                  inputRender={(props) => {
                    return <InputMask {...props} mask={datePikerMaskDefault} maskChar={null} />;
                  }}
                  // onKeyDown={(e: any) => {
                  //   const date = e.target.value?.replace(/[^\d\\.]/g, '');

                  //   let formatDate = '';

                  //   switch (date.length) {
                  //     case 2:
                  //       formatDate = date + '.';
                  //       break;

                  //     case 5:
                  //       formatDate = date + '.';
                  //       break;

                  //     // case 10:
                  //     //   formatDate = date.slice(0, 9);
                  //     //   break;

                  //     default:
                  //       formatDate = date;
                  //       break;
                  //   }

                  //   e.target.value = formatDate;
                  // }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={12}>
              <Form.Item
                label="Имя"
                name="firstName"
                rules={[
                  { required: true, message: '' },
                  {
                    validator: fioValidator,
                    message: 'Недопустимые символы',
                  },
                  {
                    validator: fioLengthValidator,
                    message: 'Максимум 64 символа',
                  },
                ]}
              >
                <Input placeholder="Введите имя" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Семейное положение" name="maritalStatusId">
                <Select placeholder="Выберите из списка">
                  {maritalStatus
                    .filter((el) => !el.deleted)
                    .map((status) => {
                      return (
                        <Select.Option key={status.id} value={status.id}>
                          {status.name}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={12}>
              <Form.Item
                shouldUpdate={(prevValues, currentValues) => prevValues?.noMiddleName !== currentValues?.noMiddleName}
              >
                {({ getFieldValue }) => {
                  const noMiddleName = getFieldValue('noMiddleName');
                  return (
                    <Form.Item
                      name="middleName"
                      label="Отчество"
                      rules={[
                        {
                          required: !noMiddleName,
                        },
                        {
                          validator: !noMiddleName ? fioValidator : undefined,
                          message: 'Недопустимые символы',
                        },
                        {
                          validator: !noMiddleName ? fioLengthValidator : undefined,
                          message: 'Максимум 64 символа',
                        },
                      ]}
                    >
                      <Input placeholder="Введите отчество" allowClear disabled={getFieldValue('noMiddleName')} />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Профессия" name="profession">
                <Input placeholder="Введите профессию" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Form.Item name="noMiddleName" valuePropName="checked">
              <Checkbox onChange={clearFieldErrors}>Нет отчества</Checkbox>
            </Form.Item>
          </Row>
          <Divider className="thin-divider mt5 mb10" />
          <Row gutter={20}>
            <Col span={12}>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    message: '',
                    pattern: emailPatternMain,
                  },
                ]}
              >
                <Input placeholder="Электронная почта" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Субъект" name="regionId" rules={[{ required: true, message: '' }]}>
                <Select placeholder="Выберите из списка">
                  {region
                    .filter((el) => !el.deleted)
                    .map((region) => (
                      <Select.Option key={region.id}>{region.name}</Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Телефон"
                name="phone"
                rules={[
                  {
                    required: true,
                    validator: phoneValidator,
                    message: '',
                  },
                ]}
                getValueFromEvent={({ maskedValue }) => getUnmaskedPhoneNumber(maskedValue)}
              >
                <MaskedInput
                  mask={[{ mask: phoneMask }]}
                  name="phone"
                  //TODO Eugene. Разобраться: без указания prefix=" " не срабатывает подсветка валидации
                  prefix=" "
                  placeholder={phoneInputPlaceholder}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={12}>
              <SuggestInput
                name="unstructuredAddress"
                required={false}
                placeholder="Город, улица, дом, строение, корпус"
                disableOnBlur={true}
                isCheckedSelectOnFront
                refValueUnstructuredAddress={refValueUnstructuredAddress}
              />
            </Col>
            <Col span={6}>
              <Form.Item label="Метро" name="subwayStationId" rules={[{ required: true, message: '' }]}>
                <Select placeholder="Выберите из списка">
                  {subwayStation
                    .filter((el) => !el.deleted)
                    .map((subway) => {
                      return (
                        <Select.Option key={subway.id} value={subway.id}>
                          {subway.name}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Квартира"
                name="apartment"
                rules={[
                  {
                    max: 4,
                    message: 'Максимум 4 символа',
                  },
                  {
                    pattern: new RegExp('^[0-9a-zA-Zа-яА-Я-/ ]*$'),
                    message: 'Недопустимые символы',
                  },
                ]}
              >
                <Input placeholder="Номер" allowClear />
              </Form.Item>
            </Col>
          </Row>
          <Divider className="thin-divider mt0 mb10" />
          <Row gutter={20}>
            <Col span={12}>
              <Form.Item label="Почему вы решили стать добровольцем?" name="volunteerReason">
                <Input placeholder="Причины по которым вы решили стать добровольцем" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Ваш приходской храм" name="church">
                <Input placeholder="Название храма" allowClear />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={12}>
              <label className="display-block">Есть ли у вас хронические заболевания?</label>
              <Radio.Group value={hasChronicDiseases} onChange={handleSetHasChronicDiseases} className="mb5">
                <Radio value={true}>Да</Radio>
                <Radio value={false}>Нет</Radio>
              </Radio.Group>
              <Form.Item name="chronicDiseases">
                <Input
                  placeholder={hasChronicDiseases ? 'Ваши заболевания' : ''}
                  allowClear
                  disabled={!hasChronicDiseases}
                />
              </Form.Item>
            </Col>
          </Row>
          <Divider className="thin-divider mt0 mb10" />
          <Row className="mb15">
            <label>Откуда Вы узнали про Службу добровольцев "Милосердие"?</label>
          </Row>
          <Row>
            <Form.Item name="awarenessSource">
              <Checkbox.Group className="mb10">
                <Row>
                  <Checkbox value={1}>Телевидение</Checkbox>
                  <Checkbox value={2} className="pl5">
                    Интернет
                  </Checkbox>
                  <Checkbox value={3} className="pl5">
                    Социальные сети
                  </Checkbox>
                  <Checkbox value={4} className="pl5">
                    От знакомых
                  </Checkbox>
                </Row>
                <Row>
                  <Checkbox value={5}>Другой источник</Checkbox>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Row>
          <Row>
            <Col span={12} className="pr10">
              <Form.Item
                className="mt5"
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.awarenessSource.length !== currentValues.awarenessSource.length
                }
              >
                {({ getFieldValue }) =>
                  getFieldValue('awarenessSource').includes(5) ? (
                    <Form.Item name="otherSource">
                      <Input placeholder="Ваш источник" allowClear />
                    </Form.Item>
                  ) : (
                    <Input disabled />
                  )
                }
              </Form.Item>
            </Col>
          </Row>
          <Divider className="thin-divider mt0 mb10" />
          <Row gutter={15}>
            <Col span={12} className="pr10">
              <Form.Item
                name="password"
                label="Введите пароль"
                rules={[
                  {
                    required: true,
                    message: '',
                  },
                  {
                    pattern: new RegExp(/^(?=.*[a-z])(?=.*\d)[a-zA-Z\d]{8,}$/),
                    message: 'Пароль ненадежный, введите другой пароль',
                  },
                ]}
              >
                <Input.Password allowClear />
              </Form.Item>
              <Form.Item
                name="confirm"
                label="Подтвердите пароль"
                dependencies={['password']}
                rules={[
                  {
                    required: true,
                    message: '',
                  },
                  {
                    pattern: new RegExp(/^(?=.*[a-z])(?=.*\d)[a-zA-Z\d]{8,}$/),
                    message: 'Пароль ненадежный, введите другой пароль',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error(''));
                    },
                  }),
                ]}
              >
                <Input.Password />
              </Form.Item>
            </Col>
            <Col>
              Надежный пароль должен содержать:
              <ul className="password-list">
                <li>Не менее 8 символов</li>
                <li>Цифры</li>
                <li>Хотя бы одну букву</li>
              </ul>
            </Col>
          </Row>
        </Form>
        <div className="mb40">
          <div style={{ width: '675px' }} className="ml-auto mr-auto mt15">
            <div
              dangerouslySetInnerHTML={{
                __html: properties.personal_data_processing,
              }}
            />
          </div>
          <Button type="primary" htmlType="button" className="mt20 mb10" onClick={form.submit}>
            Отправить
          </Button>
          <div className="text-center">
            <span>Уже зарегистрированы?</span>
            <Link className="text-bold text-underline ml10" to="/login">
              Войти
            </Link>
          </div>
          <a className="text-bold mt15" href={`${properties.privacy_policy}`} rel="noopener noreferrer" target="_blank">
            Политика конфиденциальности
          </a>
        </div>
      </div>
    </div>
  );
};
