import React from 'react';

export const FindAndFormatLinkForText = ({ text }: { text?: string }): JSX.Element => {
  const res: string[] = [];

  text?.replace(
    /((?:https?:\/\/|ftps?:\/\/|\bwww\.)(?:(?![.,?!;:()]*(?:\s|$))[^\s]){2,})|(\n+|(?:(?!(?:https?:\/\/|ftp:\/\/|\bwww\.)(?:(?![.,?!;:()]*(?:\s|$))[^\s]){2,}).)+)/gim,
    (_, link, text) => {
      res.push(
        link ? (
          <a
            href={(link[0] === 'w' ? '//' : '') + link}
            target="_blank"
            rel="noopener noreferrer"
            onClick={(e) => e.stopPropagation()}
          >
            {link}
          </a>
        ) : (
          text
        )
      );
      return link;
    }
  );

  return <span>{res}</span>;
};
