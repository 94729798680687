import React, { FC } from 'react';
import { Row, Col, Collapse, Form, Select, Input, DatePicker, Button } from 'antd';
import MaskedInput from 'antd-mask-input';
import { useAppSelector } from '../../store/hooks';
import usePhoneMask from '../../lib/utils/usePhoneMask';
import { displayFormat } from '../../lib/utils/formatDate';
import { useMainContext } from '../../containers/MainContainer/MainContext';
import { checkIsLoading } from '../../lib/utils/checkLoadingStatus';
import selectFilterOption, { selectFilterOptionIcon } from '../../lib/utils/selectFilterOption';

export const SearchApplications: FC = () => {
  const { phoneMask, phoneInputPlaceholder, getUnmaskedPhoneNumber } = usePhoneMask();
  const { personCategory, appSubject, regularityOfAssistance, region, subwayStation, appStatus } = useAppSelector(
    (state) => state.dictionaries
  );
  const { projects, projectsLoading } = useAppSelector((state) => state.projects);
  const { hasAccess, searchApplicationsState, findDynamicSVGReactElement } = useMainContext();

  const onChangeRegionId = () => {
    searchApplicationsState.handleSetSearchFormFields({
      projectIds: [],
    });
  };

  const leftSearchBlock = (
    <>
      <Row gutter={20}>
        <Col span={5}>
          <Form.Item name="appStatusId">
            <Select
              placeholder="Статус"
              allowClear
              mode="multiple"
              maxTagCount={1}
              showArrow
              showSearch
              filterOption={selectFilterOption}
            >
              {appStatus
                .filter(({ id }) => {
                  const excludeClosed = searchApplicationsState.getSearchFormFieldValue('appStatusIdExclude').length;
                  return excludeClosed ? id !== 6 : true;
                })
                .map(({ id, name }) => (
                  <Select.Option key={id} value={id}>
                    {name}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item name="regularityAssistanceId">
            <Select placeholder="Регулярность помощи" allowClear>
              {regularityOfAssistance.map(({ id, name }) => (
                <Select.Option key={id} value={id}>
                  {name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item name="wardFio">
            <Input placeholder="Подопечный" allowClear />
          </Form.Item>
        </Col>
        <Col span={9}>
          <Form.Item name="address">
            <Input placeholder="Город, улица, дом, строение, корпус" allowClear />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col span={5}>
          <Form.Item name="regionId">
            <Select
              placeholder="Субъект РФ"
              onSelect={onChangeRegionId}
              allowClear
              onClear={onChangeRegionId}
              disabled={!hasAccess(['APPLICATIONS.READ.ALLREGIONS']) || region?.length === 1}
            >
              {region.map(({ id, name }) => (
                <Select.Option key={id} value={id}>
                  {name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item name="subjectId">
            <Select placeholder="Вид помощи" allowClear showSearch filterOption={selectFilterOptionIcon}>
              {Array.isArray(appSubject) &&
                [...appSubject]
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map(({ id, name }) => (
                    <Select.Option key={id} value={id}>
                      <div className="display-flex" style={{ justifyContent: 'center', alignItems: 'center' }}>
                        <div
                          className="display-flex icon-application-select"
                          style={{ alignItems: 'center', minWidth: 20, maxWidth: 20 }}
                        >
                          {findDynamicSVGReactElement('app_subject', 'help', 'iconName', id)}
                        </div>
                        <span className="ml5 ant-select-item-option-content">{name}</span>
                      </div>
                    </Select.Option>
                  ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item name="responsibleFio">
            <Input placeholder="Ответственный" allowClear />
          </Form.Item>
        </Col>
        <Col span={9}>
          <Form.Item name="subwayStationId">
            <Select placeholder="Метро" allowClear showSearch filterOption={selectFilterOption}>
              {subwayStation.map(({ id, name }) => (
                <Select.Option key={id} value={id}>
                  {name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col span={10}>
          <Form.Item dependencies={['regionId', 'projectIds']} className="form-item-dependencies">
            {() => {
              const regionId = searchApplicationsState.getSearchFormFieldValue('regionId');
              const projectIds = searchApplicationsState.getSearchFormFieldValue('projectIds');
              // Вывод отображения всех выбранных проектов
              const getProjectTitle = () => {
                if (!regionId) return 'Необходимо выбрать субъект РФ';
                if (!Array.isArray(projects)) return 'Нет доступных проектов';
                return projectIds.map((projectId) => projects.find((item) => item.id === projectId)?.title).join('\n');
              };

              return (
                <div title={getProjectTitle()}>
                  <Form.Item name="projectIds">
                    <Select
                      placeholder={projects.length === 0 ? 'Нет доступных проектов' : 'Проект'}
                      allowClear
                      mode="multiple"
                      maxTagCount={3}
                      maxTagTextLength={15}
                      disabled={!regionId || projects.length === 0}
                      loading={checkIsLoading(projectsLoading)}
                      showSearch
                      filterOption={selectFilterOption}
                      showArrow={true}
                    >
                      {Array.isArray(projects) &&
                        projects
                          .filter((project) => project.regionId === regionId)
                          .map(({ id, title }) => (
                            <Select.Option key={id} value={id}>
                              {title}
                            </Select.Option>
                          ))}
                    </Select>
                  </Form.Item>
                </div>
              );
            }}
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item name="phone" getValueFromEvent={({ maskedValue }) => getUnmaskedPhoneNumber(maskedValue)}>
            <MaskedInput
              mask={[{ mask: phoneMask }]}
              name="phone"
              //TODO Eugene. Разобраться: без указания prefix=" " не срабатывает подсветка валидации
              prefix=" "
              placeholder={phoneInputPlaceholder}
              allowClear
            />
          </Form.Item>
        </Col>
        <Col span={9}>
          <Form.Item name="personCategoryIds">
            <Select
              placeholder="Категория"
              allowClear
              mode="multiple"
              maxTagCount={2}
              maxTagTextLength={15}
              showSearch
              filterOption={selectFilterOption}
              showArrow={true}
            >
              {personCategory.map(({ id, name }) => (
                <Select.Option key={id} value={id}>
                  <span>{name}</span>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item className="applications-list-search-textArea" name="applicationText">
            <Input.TextArea placeholder="Текст просьбы" autoSize={{ minRows: 3, maxRows: 3 }} />
          </Form.Item>
        </Col>
      </Row>
    </>
  );

  const rightSearchBlock = (
    <>
      <Row>
        <Col span={24}>
          <Form.Item label="Дата получения" labelCol={{ span: 11 }} name="createDate">
            <DatePicker.RangePicker allowClear format={displayFormat} placeholder={['начальная', 'конечная']} />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item label="Дата закрытия" labelCol={{ span: 11 }} name="closeDate">
            <DatePicker.RangePicker allowClear format={displayFormat} placeholder={['начальная', 'конечная']} />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item label="Срок завершения" labelCol={{ span: 11 }} name="deadline">
            <DatePicker.RangePicker allowClear format={displayFormat} placeholder={['начальный', 'конечный']} />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item label="Дата исполнения" labelCol={{ span: 11 }} name="executionDate">
            <DatePicker.RangePicker allowClear format={displayFormat} placeholder={['начальная', 'конечная']} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={20} className="mt5">
        <Col className="ml-auto">
          <Button onClick={searchApplicationsState.resetSearchFormFields}>Сбросить</Button>
        </Col>
        <Col className="mr-auto">
          <Button type="primary" htmlType="submit">
            Применить
          </Button>
        </Col>
      </Row>
    </>
  );

  return (
    <Collapse expandIconPosition="end" ghost className="applications-list-search" defaultActiveKey="1">
      <Collapse.Panel header="Расширенный поиск" key="1" className="font-size-14 font-roboto">
        <Form
          fields={searchApplicationsState.searchFormFields}
          onValuesChange={searchApplicationsState.handleSetSearchFormFields}
          onFinish={searchApplicationsState.getApplicationsList}
        >
          <Row gutter={20}>
            <Col span={17}>{leftSearchBlock}</Col>
            <Col span={7}>{rightSearchBlock}</Col>
          </Row>
        </Form>
      </Collapse.Panel>
    </Collapse>
  );
};
